import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
// Import Swiper styles
import 'swiper/css'

import styled from 'styled-components'

const Slider = props => {
  const { data, name } = props
  return (
    <>
      <Swiper
        spaceBetween={70}
        slidesPerView={'auto'}
        centeredSlides={true}
        slidesPerColumn={1}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {data && data.length > 0 ? (
          data.map((item, index) => (
            <SlideStyled key={index}>
              <Img
                src={`${process.env.REACT_APP_API}public/${name}/${item.image}`}
                alt={'imagen'}
              />
            </SlideStyled>
          ))
        ) : (
          <SlideStyled>
            <Img src="https://via.placeholder.com/150x150" />
          </SlideStyled>
        )}
      </Swiper>
    </>
  )
}

export default Slider

const Img = styled.img`
  height: 50px;
  object-fit: contain;
`

const SlideStyled = styled(SwiperSlide)`
  width: auto;
`
