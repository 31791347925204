import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { create, getAll } from '../../actions'
import styled from 'styled-components'
import { AiOutlineInstagram } from 'react-icons/ai'
import { FaFacebookF } from 'react-icons/fa'
import logo from '../../assets/static/logo_web.svg'
import { HashLink } from 'react-router-hash-link'

const Footer = props => {
  const { rrss } = props
  useEffect(() => {
    props.getAll('rrss', 'GET_RRSS')
    // eslint-disable-next-line
  }, [])

  if (!rrss) {
    return <h1>Cargando...</h1>
  }
  return (
    <Main>
      <Container>
        <Left>
          <A to="">
            <b>Menú</b>
          </A>
          <A to="/#home" smooth>
            Inicio
          </A>
          <A to="/#about" smooth>
            Nosotros
          </A>
          <A to="/#products" smooth>
            Productos
          </A>
          <A to="/#services" smooth>
            Servicios
          </A>
          <A to="/#contact" smooth>
            Contacto
          </A>
        </Left>
        <Img src={logo} alt="logo" />
        <IconContainer>
          <a href={`${rrss[0].link}`} target="blank">
            <Icon>
              <AiOutlineInstagram />
            </Icon>
          </a>
          <a href={`${rrss[1].link}`} target="blank">
            <Icon>
              <FaFacebookF />
            </Icon>
          </a>
        </IconContainer>
      </Container>
      <P>Todos los derechos reservados ADVI 2023.</P>
    </Main>
  )
}

const mapStateToProps = state => {
  return {
    rrss: state.rrss.rrss,
  }
}

const mapDispatchToProps = {
  create,
  getAll,
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)

const Main = styled.footer`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1000px) 1fr;
  color: #fff;
  position: relative;
  background-color: ${({ theme }) => theme.primary};
  padding-top: 50px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    grid-column: 1;
    padding: 0 15px;
  }
`

const A = styled(HashLink)`
  display: flex;
  font-family: 'PT Sans', sans-serif;
  align-items: center;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`

const P = styled.p`
  grid-column: 2/3;
  display: flex;
  font-family: 'PT Sans', sans-serif;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (max-width: 768px) {
    flex-direction: column;
    grid-column: 1;
    padding: 0 15px;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
  }
`

const Img = styled.img`
  height: 100%;
  object-fit: contain;

  @media (max-width: 768px) {
    height: 100px;
    margin-bottom: 25px;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.div`
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    color: ${({ theme }) => theme.primary};
  }
`
