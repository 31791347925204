import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from '../layout'
import Login from '../pages/Login'
import Home from '../pages/Home'
import Users from '../pages/Users'
import UsersCreate from '../pages/Users/Create'
import UsersUpdate from '../pages/Users/Update'

import Banners from '../pages/Banners'
import BannerCreate from '../pages/Banners/create'
import BannerUpdate from '../pages/Banners/update'
import Products from '../pages/Products'
import ProductCreate from '../pages/Products/create'
import Contact from '../pages/Contact'

import Customers from '../pages/Customers'
import CustomerCreate from '../pages/Customers/create'

import Rrss from '../pages/Rrss'

const App = ({ loggedIn }) => {
  if (loggedIn) {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Products />} />
            <Route
              path="/dashboard/products/create"
              element={<ProductCreate />}
            />
             <Route path="/dashboard/customers" element={<Customers />} />
            <Route
              path="/dashboard/customers/create"
              element={<CustomerCreate />}
            />
            <Route path="/dashboard/banners" element={<Banners />} />
            <Route
              path="/dashboard/banners/create"
              element={<BannerCreate />}
            />
            <Route
              path="/dashboard/banners/:id"
              element={<BannerUpdate />}
            />
            <Route path="/dashboard/contact" element={<Contact />} />
            <Route path="/dashboard/rrss" element={<Rrss />} />
            <Route path="/dashboard/users" element={<Users />} />
            <Route
              path="/dashboard/users/create"
              element={<UsersCreate />}
            />
            <Route
              path="/dashboard/users/:id"
              element={<UsersUpdate />}
            />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  }
}

export default App
