import React from 'react'
import styled from 'styled-components'
import Banner from './Banner'
import ProductsTop from './ProductsTop'
import Products from './Products'
import ProductsSlider from './ProductsSlider'
import About from './About'
import Services from './Services'
import Customers from './Customers'
import Contact from './Contact'

const Home = () => {
  return (
    <Main id="home">
      <Banner />
      <About />
      <ProductsTop />
      <ProductsSlider />
      <Products />
      <Services />
      <Customers />
      <Contact />
    </Main>
  )
}

export default Home

const Main = styled.main``
