import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import img1 from '../../assets/static/proyects.svg'
import img2 from '../../assets/static/instalados.svg'

const Vision = props => {
  const { banners } = props

  useEffect(() => {
    props.getAll('banners', 'GET_BANNERS')
    //eslint-disable-next-line
  }, [])

  if (!banners) {
    return <h1>Cargando...</h1>
  }

  return (
    <Main id="products">
      <Box>
        <BoxCard>
          <Img src={img1} alt="icono" />
          <BoxText>
            +120 <br /> proyectos
          </BoxText>
        </BoxCard>
        <BoxCard>
          <Img src={img2} alt="icono" />
          <BoxText>
            +2,500 m2 <br /> instalados
          </BoxText>
        </BoxCard>
      </Box>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
    products: state.products.products,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vision)

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  background-color: ${({ theme }) => theme.white};
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Box = styled.div`
  grid-column: 1/4;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const BoxCard = styled.div`
  display: flex;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const Img = styled.img`
  height: 100px;
  object-fit: contain;
  margin-right: 20px;
`
