import { combineReducers } from 'redux'
import reducerApp from './reducer'
import users from '../pages/Users/reducer'
import customers from '../pages/Customers/reducer'
import banners from '../pages/Banners/reducer'
import products from '../pages/Products/reducer'
import contact from '../pages/Contact/reducer'
import rrss from '../pages/Rrss/reducer'

const reducer = combineReducers({
  reducerApp,
  users,
  customers,
  banners,
  products,
  contact,
  rrss
})

export default reducer
