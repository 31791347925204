import React from 'react'
import styled from 'styled-components'

const Title = ({ children, color, size }) => {
  return (
    <TitleStyled color={color} size={size}>
      { children }
    </TitleStyled>
  )
}

export default Title

const TitleStyled = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: ${({ size }) => (size === 'big' ? '500' : '300')};
  font-size: ${({ size }) =>
    size === 'big' ? '70px' : size === 'mid' ? '50px' : '20px'};
  margin-bottom: ${({ size }) =>
    size === 'big' ? '50px' : size === 'mid' ? '10px' : '10px'};
  letter-spacing: ${({ size }) =>
    size === 'big' ? '4px' : size === 'mid' ? '3px' : '2px'};
  color: ${props => {
    switch (props.color) {
      case 'secondary':
        return props.theme.secondary
      case 'white':
        return props.theme.white
      case 'info':
        return props.theme.info
      case 'success':
        return props.theme.success

      default:
        return props.theme.primary
    }
  }};

  border-left: ${({ size }) => (size === 'mid' ? '2px solid' : 'none')};
  border-color: ${({ theme }) => theme.secondary};
  padding: ${({ size }) => (size === 'mid' ? '10px' : '0')};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: ${({ size }) => (size === 'mid' ? 'block' : 'none')};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.secondary};
    bottom: 0;
    left: -6px;
  }

  @media (max-width: 768px) {
    font-size: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '15px'};
    margin-bottom: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '15px'};
    letter-spacing: ${({ size }) =>
      size === 'big' ? '4px' : size === 'mid' ? '3px' : '1px'};
  }
`
