import React, { useState } from 'react'
import { connect } from 'react-redux'
import { create } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import Input from '../../components/InputWeb'
import Button from '../../components/ButtonWeb'
import Swal from 'sweetalert2'

const Contact = props => {
  const [form, setForm] = useState({})

  const handleForm = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    props
      .create('contact', 'POST_BANNER', form)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Mensaje enviado',
          text: 'Pronto nos pondremos en contacto contigo',
          showConfirmButton: false,
          timer: 1500,
        })
      })
      .then(() => {
        document.getElementById('contactForm').reset()
      })
  }

  return (
    <Main id="contact">
      <Container>
        <TextContainer>
          <Title size={'mid'}>Contacto</Title>
          <Subtitle>
            ¡Agenda tu visita y obtén tu presupuesto gratis!
          </Subtitle>
        </TextContainer>
        <Form id="contactForm" onSubmit={e => handleSubmit(e)}>
          <Left>
            <Input
              placeholder="Nombre"
              name="name"
              type="text"
              onChange={e => handleForm(e)}
            />
            <Input
              placeholder="Apellido"
              name="lastname"
              type="text"
              onChange={e => handleForm(e)}
            />
            <Input
              placeholder="Teléfono"
              name="phone"
              type="text"
              onChange={e => handleForm(e)}
            />
            <Input
              placeholder="Correo"
              name="email"
              type="email"
              onChange={e => handleForm(e)}
            />
            <Input
              placeholder="Estado de la República"
              name="state"
              type="text"
              onChange={e => handleForm(e)}
            />

            <Button color="white" type="submit">
              Solicitar información
            </Button>
          </Left>
          <div>
            <Textarea
              placeholder="Escriba aquí su mensaje y uno de nuestros asesores se pondrá en contacto con usted."
              name="mesaje"
              onChange={e => handleForm(e)}
            />
            <TextContainer2>
              <Text>
                <span>Cel.</span> <b>+52</b> 244 106 4941
              </Text>
              <Text>
                <span>Correo</span>. ventas@<b>advi.mx</b>
              </Text>
            </TextContainer2>
          </div>
        </Form>
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  create,
}

export default connect(null, mapDispatchToProps)(Contact)

const Main = styled.div`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  position: relative;
  background-color: ${({ theme }) => theme.white};
  padding: 100px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    grid-column: 1/2;
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`

const Form = styled.form`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 20px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const Textarea = styled.textarea`
  resize: none;
  padding: 0.375rem 0.75rem;
  width: 100%;
  height: 90%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  outline-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  background-color: ${({ theme }) => theme.white};
  background-clip: padding-box;
  border: ${({ theme }) => theme.border};
  border-color: ${({ theme }) => theme.primary};
  border-radius: 10px;
  box-shadow: none;
  margin-bottom: 0.375rem;
  font-family: 'PT Sans', sans-serif;
  transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  box-sizing: border-box;

  @media (max-width: 768px) {
    height: 200px;
  }
`

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.secondary};
  margin: 0;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
`

const TextContainer2 = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: none;
  }
`

const Text = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.primary};
  margin: 0;
  font-weight: 100;
  span {
    font-weight: 700;
    color: ${({ theme }) => theme.secondary};
  }
  b {
    font-weight: 700;
  }
`
