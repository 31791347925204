import React from 'react'
import styled from 'styled-components'
import Title from '../../components/Title'
import icon from '../../assets/static/icon.svg'
import img1 from '../../assets/static/1.png'
import img2 from '../../assets/static/2.png'

const Vision = () => {
  return (
    <Main id="about">
      <Container>
        <CardBox>
          <Card>
            <Icon src={icon} />
            <Title size="small">Servicio Personalizado</Title>
            <TextSmall>
              Contamos con personal capacitado que se encarga de
              atender a cada uno de nuestros clientes y resolver sus
              dudas en todo momento, además de acompañarlos en toda la
              etapa del proyecto.
            </TextSmall>
          </Card>
          <Card>
            <Icon src={icon} />
            <Title size="small">Proyectos Integrales</Title>
            <TextSmall>
              Entendemos los requerimientos de cada proyecto, por lo
              cual, ayudamos a concretarlo de la mejor manera posible
              durante la fase de diseño, suministro, instalación y
              mantenimiento.
            </TextSmall>
          </Card>
          <Card>
            <Icon src={icon} />
            <Title size="small">Calidad y Garantía</Title>
            <TextSmall>
              Nuestros productos y servicios de instalación y
              mantenimiento cuentan con garantías inigualables,
              logrando que tu espacio e inversión estén protegidos por
              mucho más tiempo.
            </TextSmall>
          </Card>
        </CardBox>
        <Title size="mid" color={'primary'}>
          Nosotros
        </Title>
        <Text>
          Somos una empresa <b>mexicana</b> establecida en la ciudad
          de
          <b>Puebla</b> que, desde <b>2015</b>, se dedica a la venta,
          distribución e instalación de{' '}
          <b>cortinas, persianas, toldos, pisos laminados</b>y{' '}
          <b>alfombras</b>. Desde entonces, hemos tenido proyectos
          <b>residenciales</b>, del <b>sector público</b> y{' '}
          <b>privado</b>, en muchas partes del <b>País</b> como{' '}
          <b> Guadalajara, Tlaxcala, Veracruz, Ciudad de México</b>,
          entre otras.
        </Text>
        <CardLeft>
          <CardContainer>
            <TitleStyled size="small">Misión</TitleStyled>
            <Text2>
              Crear espacios agradables dentro de hogares, escuelas y
              oficinas para nuestros clientes a través de un servicio
              personalizado, buscando siempre ofrecer los mejores
              precios y tiempos de respuesta.
            </Text2>
          </CardContainer>
          <Img src={img2} alt="vision" />
        </CardLeft>
        <CardRight>
          <Img src={img1} alt="vision" />
          <CardContainer>
            <TitleStyled size="small">Visión</TitleStyled>
            <Text2>
              Posicionarnos como principal distribuidor e instalador
              de cortinas y persianas en el País, ofreciendo productos
              de la más alta calidad, trabajando de manera ética y
              responsable con nuestros clientes, empleados y socios
              comerciales.
            </Text2>
          </CardContainer>
        </CardRight>
      </Container>
    </Main>
  )
}

export default Vision

const Main = styled.div`
  background-color: ${({ theme }) => theme.white};
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  color: #000;
  font-family: 'Open Sans', sans-serif;
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`

const Img = styled.img`
  object-fit: contain;
  height: 200px;
`

const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 2;
  color: #c4c4c4;
  margin: 30px 0;
`

const Text2 = styled(Text)`
  color: ${({ theme }) => theme.primary};
  line-height: 3;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 0;
  }
`

const TextSmall = styled.p`
  margin: 0;
  font-weight: 100;
  color: #c4c4c4;
`

const Icon = styled.img`
  height: 30px;
  margin-bottom: 20px;
`

const CardBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: -100px;
  gap: 50px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-top: 0;
    padding: 0 15px;
  }
`

const Card = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.white};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  line-height: 1.5;
`

const CardLeft = styled(Card)`
  text-align: left !important;
  margin-bottom: 40px;
  align-items: flex-start;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 15px 25px 15px;
  }
`

const CardRight = styled(Card)`
  text-align: right !important;
  margin-bottom: 40px;
  align-items: flex-end;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 15px;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    align-items: center;
  }
`

const TitleStyled = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: ${({ size }) => (size === 'big' ? '500' : '300')};
  font-size: 30px;
  margin-bottom: ${({ size }) =>
    size === 'big' ? '50px' : size === 'mid' ? '10px' : '10px'};
  letter-spacing: ${({ size }) =>
    size === 'big' ? '4px' : size === 'mid' ? '3px' : '2px'};
  color: ${props => {
    switch (props.color) {
      case 'secondary':
        return props.theme.secondary
      case 'white':
        return props.theme.white
      case 'info':
        return props.theme.info
      case 'success':
        return props.theme.success

      default:
        return props.theme.primary
    }
  }};

  border-left: ${({ size }) =>
    size === 'mid' ? '2px solid' : 'none'};
  border-color: ${({ theme }) => theme.secondary};
  padding: ${({ size }) => (size === 'mid' ? '10px' : '0')};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: ${({ size }) => (size === 'mid' ? 'block' : 'none')};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.secondary};
    bottom: 0;
    left: -6px;
  }

  @media (max-width: 768px) {
    font-size: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '25px'};
    margin-bottom: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '15px'};
    letter-spacing: ${({ size }) =>
      size === 'big' ? '4px' : size === 'mid' ? '3px' : '1px'};
    margin-top: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '15px'};
  }
`
