import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import Button from '../../components/Button'
import { AiOutlineInstagram } from 'react-icons/ai'
import { FaFacebookF } from 'react-icons/fa'
import bgWood from '../../assets/static/bg_wood.png'
import inst from '../../assets/static/inst.svg'
import man from '../../assets/static/man.svg'
import red from '../../assets/static/red.svg'
import { HashLink } from 'react-router-hash-link'

const Vision = props => {
  const { banners } = props

  useEffect(() => {
    props.getAll('banners', 'GET_BANNERS')
    //eslint-disable-next-line
  }, [])

  if (!banners) {
    return <h1>Cargando...</h1>
  }
  return (
    <Main id="services">
      <Box>
        <BoxCard>
          <BoxText>
            <Icon>
              <AiOutlineInstagram />
            </Icon>
            advimx
          </BoxText>
        </BoxCard>
        <BoxCard>
          <BoxText>
            <b>+52</b> 244 106 4941
          </BoxText>
        </BoxCard>
        <BoxCard>
          <BoxText>
            <Icon>
              <FaFacebookF />
            </Icon>
            advimexico
          </BoxText>
        </BoxCard>
      </Box>
      <Container>
        <TextContainer>
          <Title size="mid">Servicios</Title>
        </TextContainer>
        <Grid>
          <Img2
            src={`${process.env.REACT_APP_API}public/banners/${banners[2].image}`}
            alt="image"
          />
          <GridBox>
            <Text>
              Nuestro equipo de especialistas y soporte técnico
              especializado de la marca, garantizan un trabajo limpio,
              rápido, seguro y de calidad.
            </Text>
            <Item>
              <Logo src={inst} alt="logo" />
              Instalación
            </Item>
            <Item>
              <Logo src={man} alt="logo" />
              Mantenimiento
            </Item>
            <Item>
              <Logo src={red} alt="logo" />
              Rediseño
            </Item>
            <HashLink to="/#contact" smooth>
              <Button>Cotiza ahora</Button>
            </HashLink>
          </GridBox>
        </Grid>
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vision)

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  background-color: ${({ theme }) => theme.white};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Box = styled.div`
  grid-column: 1/4;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
  background-image: url(${bgWood});
  color: ${({ theme }) => theme.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const BoxCard = styled.div`
  display: flex;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

const BoxText = styled.div`
  display: flex;
  align-items: center;
  font-size: 2.5rem;

  & b {
    margin-right: 15px;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const Icon = styled.div`
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.8rem;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  @media (max-width: 768px) {
  }
`

const TextContainer = styled.div`
  text-align: center;
  display: flex;
  align-self: center;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin: 30px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 15px;
    gap: 20px;
  }
`

const GridBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 768px) {
    align-items: center;
  }
`

const Img2 = styled.img`
  width: 100%;
  border-radius: 15px;
`

const Text = styled.p`
  color: #9b9b9b;
  line-height: 2;
  margin-bottom: 20px;
  text-align: right;
  @media (max-width: 768px) {
    text-align: center;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #9b9b9b;
  min-width: 250px;
`

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`
