import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import Slider from '../../containers/Slider'

const Vision = props => {
  const { customers } = props

  useEffect(() => {
    props.getAll('customers', 'GET_CUSTOMERS')
    //eslint-disable-next-line
  }, [])

  if (!customers) {
    return <h1>Cargando...</h1>
  }
  return (
    <Main>
      <Container>
        <TextContainer>
          <Title size="small">Nuestros clientes</Title>
        </TextContainer>
        <Text>
          Tenemos <b>+60 clientes</b> al rededor de la República
          Mexicana que respaldan nuestros proyectos.
        </Text>
      </Container>
      <SliderContainer>
        <Slider data={customers} name="customers" />
      </SliderContainer>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    customers: state.customers.customers,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vision)

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding-bottom: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`

const TextContainer = styled.div`
  text-align: center;
  display: flex;
  align-self: center;
`

const Text = styled.p`
  color: #9b9b9b;
  line-height: 2;
  margin-bottom: 20px;
  text-align: center;
`

const SliderContainer = styled.div`
  grid-column: 1/4;
  width: 100vw;
`
