import React, { useState, useEffect } from 'react'
import styles from 'styled-components'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { VscCircleOutline } from 'react-icons/vsc'
import { FiChevronDown } from 'react-icons/fi'
import { BsChatLeftDots } from 'react-icons/bs'
import {
  AiOutlineInbox,
  AiOutlineFileImage,
  AiOutlineUser,
} from 'react-icons/ai'
import logo from '../../assets/static/logo.svg'
import logo_white from '../../assets/static/logo_white.svg'
import logo_m from '../../assets/static/logo_m.svg'
import logo_m_white from '../../assets/static/logo_m_white.svg'

const AsideComponent = props => {
  const { theme, toggleBar, toggleBarMouse, toggleMouseHover, user } =
    props
  const location = useLocation()
  const [menus, setMenus] = useState([])

  useEffect(() => {
    setMenus([
      {
        id: 1,
        title: 'Productos',
        icon: <AiOutlineInbox />,
        link: '/dashboard',
      },
      {
        id: 2,
        title: 'Clientes',
        icon: <AiOutlineUser />,
        link: '/dashboard/customers',
      },
      {
        id: 3,
        title: 'Banners',
        icon: <AiOutlineFileImage />,
        link: '/dashboard/banners',
      },
      {
        id: 5,
        title: 'Contacto',
        icon: <BsChatLeftDots />,
        link: '/dashboard/contact',
      },
      {
        id: 6,
        title: 'RRSS',
        icon: <BsChatLeftDots />,
        link: '/dashboard/rrss',
      },
      {
        id: 4,
        title: 'Usuarios',
        icon: <AiOutlineUser />,
        link: '/dashboard/users',
      },
    ])
  }, [user])

  const handleOpen = (id, isOpen) => {
    setMenus(
      menus.map(category => {
        if (category.id === id) {
          category.isOpen = !isOpen
        }
        return category
      })
    )
  }

  const finalLogo = !theme
    ? !toggleBar || toggleBarMouse
      ? logo
      : logo_m
    : !toggleBar || toggleBarMouse
    ? logo_white
    : logo_m_white

  return (
    <Aside
      toggleBar={toggleBar}
      toggleBarMouse={toggleBarMouse}
      onMouseEnter={toggleMouseHover}
      onMouseLeave={toggleMouseHover}
    >
      <Top>
        <Logo src={finalLogo} alt="logo" />
      </Top>
      <Menu>
        <Ul toggleBar={toggleBar} toggleBarMouse={toggleBarMouse}>
          <Category
            toggleBar={toggleBar}
            toggleBarMouse={toggleBarMouse}
          >
            Menú
          </Category>
          {menus.map((menu, index) =>
            menu.submenu ? (
              <li key={index}>
                <Li
                  onClick={() => handleOpen(menu.id, menu.isOpen)}
                  className={
                    menu.submenu.find(s =>
                      location.pathname.includes(s.link)
                    ) !== undefined
                      ? 'active'
                      : ''
                  }
                >
                  <div>
                    {menu.icon}
                    <Span
                      toggleBar={toggleBar}
                      toggleBarMouse={toggleBarMouse}
                    >
                      {menu.title}
                    </Span>
                  </div>
                  <FiChevronDown />
                </Li>
                <SubMenu
                  isOpen={menu.isOpen}
                  toggleBar={toggleBar}
                  toggleBarMouse={toggleBarMouse}
                >
                  {menu.submenu.map((submenu, i) => (
                    <li key={i}>
                      <SubLink
                        to={submenu.link}
                        className={
                          submenu.link === location.pathname
                            ? 'active'
                            : ''
                        }
                      >
                        <VscCircleOutline /> {submenu.title}
                      </SubLink>
                    </li>
                  ))}
                </SubMenu>
              </li>
            ) : (
              <li key={index}>
                <LinkStyled
                  toggleBar={toggleBar}
                  toggleBarMouse={toggleBarMouse}
                  to={menu.link}
                  className={
                    menu.link === location.pathname ? 'active' : ''
                  }
                >
                  <div>
                    {menu.icon}
                    <Span
                      toggleBar={toggleBar}
                      toggleBarMouse={toggleBarMouse}
                    >
                      {menu.title}
                    </Span>
                  </div>
                </LinkStyled>
              </li>
            )
          )}
        </Ul>
      </Menu>
    </Aside>
  )
}
const mapStateToProps = state => {
  return {
    user: state.reducerApp.user,
  }
}

export default connect(mapStateToProps, null)(AsideComponent)

const Aside = styles.aside`
grid-area: aside;
position: fixed;
width: ${props => (props.toggleBar ? '80px' : '270px')};
height: 100vh;
background-color: ${({ theme }) => theme.backgroundButton};
border: ${({ theme }) => theme.border};
border-collapse: collapse;
${props =>
  props.toggleBar
    ? `
  &:hover{
    width: 270px;
  }
`
    : null}

    @media (max-width: 768px) {
      width: 270px;
      margin-top: 75px;
      z-index: 99;
      transform: ${props =>
        props.toggleBar ? 'translateX(0)' : 'translateX(-100%)'};
    }

`
const Top = styles.div`
height: 75px;
border-bottom: ${({ theme }) => theme.border};
display: flex;
justify-content: center;
align-items: center;

@media (max-width: 768px) {
  display: none;
}
`

const Logo = styles.img`
  height: 36px;
`

const Menu = styles.div`

`

const Span = styles.span`
  ${props =>
    props.toggleBar && !props.toggleBarMouse ? 'display: none' : ''};

  @media (max-width: 768px) {
    display: inline-block;
   }
`

const Ul = styles.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 0;
  padding: ${props =>
    props.toggleBar && !props.toggleBarMouse
      ? '10px 0px 0 0px'
      : '10px 15px 0 0px'};
  @media (max-width: 768px) {
    padding: 10px 15px 0 0px;
  }
`

const Category = styles.li`
color: ${({ theme }) => theme.menu_category};
font-size: 12px;
text-transform: uppercase;
padding: 10px 30px;
${props =>
  props.toggleBar && !props.toggleBarMouse ? 'display: none' : ''};
@media (max-width: 768px) {
  display: inline-block;
 }
`

const LinkStyled = styles(Link)`
text-decoration: none;
position: relative;
display: flex;
padding: 10px 30px;
border-radius:
${props =>
  props.toggleBar && !props.toggleBarMouse
    ? ' 0 0 0 0'
    : ' 0 60px 60px 0'};
color: ${({ theme }) => theme.colorButtonCircle};
font-size: 14px;
justify-content: space-between;
svg{
  margin-right: 5px;
}
&.active {
  color: ${({ theme }) => theme.menu_link_active};
  background: ${({ theme }) =>
    `linear-gradient(to bottom right, ${theme.primary} 0%, ${theme.primary_scale1} 100%) !important`};
  box-shadow: 0 7px 12px 0 ${({ theme }) => theme.primary_scale2};
}

@media (max-width: 768px) {
 border-radius:  0 60px 60px 0;
}

`

const Li = styles.div`
text-decoration: none;
position: relative;
display: flex;
padding: 10px 30px;
border-radius: 0 60px 60px 0;
color: ${({ theme }) => theme.colorButtonCircle};
font-size: 14px;
justify-content: space-between;
svg{
  margin-right: 5px;
}
&.active {
  color: ${({ theme }) => theme.menu_link_active};
  background: ${({ theme }) =>
    `linear-gradient(to bottom right, ${theme.primary} 0%, ${theme.primary_scale1} 100%) !important`};
  box-shadow: 0 7px 12px 0 ${({ theme }) => theme.primary_scale2};
}
`

const SubMenu = styles.ul`
  ${props =>
    props.toggleBar && !props.toggleBarMouse ? 'display: none' : ''};
  list-style: none;
  font-size: 0.8rem !important;
  padding-left: 20px;
  margin-left: 26px;
  transition: all 0.2s;
  transition: max-heigth ${({ isOpen }) => (isOpen ? '1s' : '.6s')};
  max-height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  transform: ${props => (props.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: 0 0 0;
`

const SubLink = styles(Link)`
  align-items: center;
  padding: 5px 30px;
  font-size: 13px;
  text-decoration: none;
  position: relative;
  display: flex;
  color: ${({ theme }) => theme.colorSublink};
  padding: 10px 0;
  &:hover, &.active {
    color: ${({ theme }) => theme.colorButtonCircle};

  }
`
