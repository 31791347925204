import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { updateFile, get, setTitle, getAll } from '../../actions'
import Card from '../../components/Card'
import Input from '../../components/Input'
import Button from '../../components/Button'
import styled from 'styled-components'

const CreateSupplier = props => {
  const [form, setForm] = useState({})
  const { setTitle, banner } = props
  const { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    const topbar = {
      title: 'Editar Banner',
    }
    setTitle(topbar)
    props.get(`banners/${id}`, 'GET_BANNER')

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (banner) {
      setForm({
        title: banner.title,
        text: banner.text,
        imageUrl: banner.image,
      })
    }
    // eslint-disable-next-line
  }, [banner])

  const handleForm = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleFile = e => {
    setForm({
      ...form,
      image: e.target.files,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.updateFile(`banners/${id}`, 'POST_USER', form).then(() => {
      navigate('/dashboard/banners')
    })
  }
  if (banner) {
    return (
      <Card title={'Información del usuario'}>
        <form id="formUser" onSubmit={e => handleSubmit(e)}>
          <Input
            type="text"
            name="title"
            title="Titulo"
            onChange={e => handleForm(e)}
            defaultValue={form.title}
          />

          <Input
            type="text"
            name="text"
            title="Texto"
            onChange={e => handleForm(e)}
            defaultValue={form.text}
          />
          <Img
            src={`${process.env.REACT_APP_API}public/banners/${form.imageUrl}`}
            alt={form.tilte}
          />
          <Input
            type="file"
            name="image"
            title="Imagen"
            onChange={e => handleFile(e)}
            required
          />

          <BarButton>
            <Button type="submit" color="success">
              Editar
            </Button>
            <Link to="/dashboard/banners">
              <Button className="btn --danger">Cancelar</Button>
            </Link>
          </BarButton>
        </form>
      </Card>
    )
  } else {
    return <h1>Cargando</h1>
  }
}

const mapStateToProps = state => {
  return {
    divisions: state.banners.divisions,
    roles: state.banners.roles,
    departments: state.banners.departments,
    banner: state.banners.banner,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  updateFile,
  get,
  setTitle,
  getAll,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSupplier)

const BarButton = styled.div`
  margin-top: 1rem;
`

const Img = styled.img`
  width: 150px;
`
