import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineInstagram,
} from 'react-icons/ai'
import { FaFacebookF } from 'react-icons/fa'
import logo from '../../assets/static/logo_web.svg'

const Header = props => {
  const { rrss } = props
  const [togleBar, setTogle] = useState(false)

  useEffect(() => {
    props.getAll('rrss', 'GET_RRSS')
    // eslint-disable-next-line
  }, [])

  if (!rrss) {
    return <h1>Cargando...</h1>
  }

  return (
    <HeaderS>
      <Menu togleBar={togleBar}>
        <Img src={logo} alt="" />

        <Ul togleBar={togleBar}>
          <Li>
            <HashLink
              smooth
              onClick={() => setTogle(false)}
              to="/#home"
            >
              Inicio
            </HashLink>
          </Li>

          <Li>
            <HashLink
              smooth
              onClick={() => setTogle(false)}
              to="/#about"
            >
              Nosotros
            </HashLink>
          </Li>
          <Li>
            <HashLink
              smooth
              onClick={() => setTogle(false)}
              to="/#products"
            >
              Productos
            </HashLink>
          </Li>

          <Li>
            <HashLink
              smooth
              onClick={() => setTogle(false)}
              to="/#services"
            >
              Servicios
            </HashLink>
          </Li>
          <Li>
            <HashLink
              smooth
              onClick={() => setTogle(false)}
              to="/#contact"
            >
              Contacto
            </HashLink>
          </Li>
        </Ul>
        <IconContainer>
          <a href={`${rrss[0].link}`} target="blank">
            <Icon>
              <AiOutlineInstagram />
            </Icon>
          </a>
          <a href={`${rrss[1].link}`} target="blank">
            <Icon>
              <FaFacebookF />
            </Icon>
          </a>
        </IconContainer>
        <Hamburguer onClick={() => setTogle(!togleBar)}>
          {togleBar ? <AiOutlineClose /> : <AiOutlineMenu />}
        </Hamburguer>
      </Menu>
    </HeaderS>
  )
}

const mapStateToProps = state => {
  return {
    rrss: state.rrss.rrss,
  }
}

const mapDispatchToProps = {
  getAll,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)

const HeaderS = styled.header`
  position: fixed;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  height: 64px;
  width: 100vw;
  box-sizing: border-box;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.6);
`

const Menu = styled.div`
  grid-column: 2 / 3;
  z-index: 11;
  max-width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  max-width: 100vw;
  padding: 5px 0;
  background: transparent;
  width: 100%;
  position: relative;
`

const Ul = styled.ul`
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  transition: all 1s ease-out;
  @media (max-width: 768px) {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    margin: 0;
    width: 100vw;
    height: 100vh;
    transition: all 0.5s ease-out;
    flex-direction: column;
    justify-content: center;
    boz-sizing: border-box;
    top: 0;
    left: 0;
    ${props =>
      props.togleBar === false
        ? 'transform: translateX(-100%);'
        : 'transform: translateX(0%);'}
  }
`

const Hamburguer = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  color: ${({ theme }) => theme.white};
  font-size: 1.5rem;
  font-weight: 100;
  margin-top: 10px;
  margin-right: 25px;
  padding-bottom: 10px;
  display: none;
  @media (max-width: 768px) {
    z-index: 12;
    display: block;
  }
`

const Li = styled.li`
  margin: 0 25px;
  font-size: 16px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
  & a {
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    text-align: center;

    &:hover {
      font-weight: bold;
    }

    @media (max-width: 768px) {
      font-size: 1rem;
      color: #fff;
    }
  }
`

const Img = styled.img`
  height: 50px;
  object-fit: contain;
  @media (max-width: 768px) {
    height: 35px;
    padding-left: 10px;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`

const Icon = styled.div`
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    color: rgba(0, 0, 0, 0.8);
  }
`
