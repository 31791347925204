import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import Button from '../../components/ButtonWeb'
import { HashLink } from 'react-router-hash-link'

const Banner = props => {
  const { banners } = props

  useEffect(() => {
    props.getAll('banners', 'GET_BANNERS')
    //eslint-disable-next-line
  }, [])

  if (!banners) {
    return <h1>Cargando...</h1>
  }

  return (
    <Main>
      <ImgContainer>
        <Bg
          src={`${process.env.REACT_APP_API}public/banners/${banners[0].image}`}
        />
      </ImgContainer>
      <Container>
        <Title size={'big'} color={'white'}>
          {banners[0].title}
        </Title>
        <HashLink to="/#contact" smooth>
          <Button color={'secondary'}>{banners[0].text}</Button>
        </HashLink>
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Banner)

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-color: ${({ theme }) => theme.white};
  position: relative;
  color: #fff;
`

const Container = styled.div`
  grid-column: 2/3;
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

const ImgContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
`

const Bg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
