import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import Slider from '../../containers/Slider'

const Vision = props => {
  const { products } = props

  useEffect(() => {
    props.getAll('products', 'GET_PRODUCTS')
    //eslint-disable-next-line
  }, [])

  if (!products) {
    return <h1>Cargando...</h1>
  }
  return (
    <Main>
      <Container>
        <TextContainer>
          <Title size="mid">Productos</Title>
        </TextContainer>
      </Container>
      <SliderContainer>
        <Slider data={products} name="products" />
      </SliderContainer>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    products: state.products.products,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vision)

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  background-color: ${({ theme }) => theme.white};
  padding-bottom: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 768px) {
    grid-column: 1;
  }
`

const TextContainer = styled.div`
  text-align: center;
  display: flex;
  align-self: center;
`

const SliderContainer = styled.div`
  grid-column: 1/4;
  width: 100vw;
`
