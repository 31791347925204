import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getAll } from '../../actions'
import styled from 'styled-components'
import Button from '../../components/Button'
import logos from '../../assets/static/logos.png'
import { HashLink } from 'react-router-hash-link'

const Vision = props => {
  const { banners } = props

  useEffect(() => {
    props.getAll('banners', 'GET_BANNERS')
    //eslint-disable-next-line
  }, [])

  if (!banners) {
    return <h1>Cargando...</h1>
  }

  return (
    <Main>
      <Container>
        <Grid>
          <GridBox>
            <Text>
              Somos distribuidores de las marcas más reconocidas en el
              mercado de diferentes productos, dentro de los cuales
              están:
            </Text>
            <Ul>
              <Li>Cortinas</Li>
              <Li>Persianas</Li>
              <Li>Toldos</Li>
              <Li>Alfombras</Li>
              <Li>Pisos Laminados</Li>
              <Li>Pasto Sintético, etc.</Li>
            </Ul>
            <HashLink to="/#contact" smooth>
              <Button>Cotiza ahora</Button>
            </HashLink>
          </GridBox>
          <Img2
            src={`${process.env.REACT_APP_API}public/banners/${banners[1].image}`}
            alt="image"
          />
        </Grid>
        <Text2>
          Además contamos con una amplia gama de soluciones,
          accesorios y equipos para la automatización de cortinas y
          persianas. Es importante conocer las necesidades específicas
          de cada cliente y ambiente de instalación para ofrecer el
          producto adecuado para cada aplicación.
        </Text2>
        <GridImage>
          <Logo src={logos} alt="logos" />
        </GridImage>
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  getAll,
}

const mapStateToProps = state => {
  return {
    banners: state.banners.banners,
    products: state.products.products,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vision)

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  z-index: 1;
  position: relative;
  background-color: ${({ theme }) => theme.white};
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  @media (max-width: 768px) {
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin: 30px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 15px;
  }
`

const GridBox = styled.div``

const Img2 = styled.img`
  width: 100%;
  border-radius: 15px;
`

const Text = styled.p`
  color: #9b9b9b;
  line-height: 2;
  margin-bottom: 20px;
`

const Ul = styled.ul`
  margin-bottom: 20px;
  margin: 30px 0;
`

const Li = styled.li`
  margin-bottom: 10px;
  color: #9b9b9b;
`

const Text2 = styled.p`
  color: #9b9b9b;
  line-height: 2;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.primary};
  @media (max-width: 768px) {
    text-align: center;
    padding: 0 15px;
  }
`

const GridImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  height: 100px;
  object-fit: contain;
  @media (max-width: 768px) {
    height: 50px;
  }
`
